var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.blocksData)?_c('div',{attrs:{"id":"home"}},[(_vm.blocksData.heroSlider && _vm.blocksData.heroSlider.active)?_c('HeroSlider',{attrs:{"slider-data":_vm.blocksData.heroSlider.data}}):_vm._e(),_vm._v(" "),(_vm.blocksData.video && _vm.blocksData.video.active)?_c('div',{staticClass:"hp-video-container"},[_c('HomepageVideo',{attrs:{"video-ref":"hp-video","data":_vm.blocksData.video.data,"hasMuteIcon":true,"has-play-icon":false}})],1):_vm._e(),_vm._v(" "),(_vm.blocksData.classics && _vm.blocksData.classics.active)?_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('FeaturedCollection',{attrs:{"featured-collection-data":_vm.blocksData.classics}})],1):_vm._e(),_vm._v(" "),(_vm.blocksData.collectionHome && _vm.blocksData.collectionHome.active)?_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('CollectionHome',{attrs:{"data":_vm.blocksData.collectionHome.data}})],1):_vm._e(),_vm._v(" "),(
        _vm.blocksData.projectThroughLightning &&
        _vm.blocksData.projectThroughLightning.active
      )?_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('HeroImage',{attrs:{"data":_vm.blocksData.projectThroughLightning.data}})],1):_vm._e(),_vm._v(" "),(
        _vm.blocksData.blogCarousel &&
        _vm.blocksData.blogCarousel.active &&
        _vm.blocksData.blogCarousel.data
      )?_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('LatestStories',{attrs:{"title":_vm.blocksData.blogCarousel.data.title,"link":_vm.blocksData.blogCarousel.data.link,"storiesData":_vm.blocksData.blogCarousel.data.posts}})],1):_vm._e(),_vm._v(" "),(_vm.blocksData.featuredProduct && _vm.blocksData.featuredProduct.active)?_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('FeaturedProduct',{attrs:{"data":_vm.blocksData.featuredProduct.data}})],1):_vm._e(),_vm._v(" "),(_vm.blocksData.findYourStyle && _vm.blocksData.findYourStyle.active)?_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('FindYourStyle',{attrs:{"data":_vm.blocksData.findYourStyle.data}})],1):_vm._e(),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('NeedHelp')],1),_vm._v(" "),(_vm.blocksData.brandSlider && _vm.blocksData.brandSlider.active)?_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('BrandSlider',{attrs:{"data":_vm.blocksData.brandSlider.data}})],1):_vm._e(),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[(_vm.blocksData.handcrafted && _vm.blocksData.handcrafted.active)?_c('HandCraftedBlock',{attrs:{"handcrafted-data":_vm.blocksData.handcrafted.data,"videoInsteadOfImage":true}}):_vm._e()],1)],1):_c('LoadingDots'),_vm._v(" "),_c('Website'),_vm._v(" "),_c('Organization')],1)}
var staticRenderFns = []

export { render, staticRenderFns }