<template>
  <div>
    <div id="home" v-if="blocksData">
      <HeroSlider
        v-if="blocksData.heroSlider && blocksData.heroSlider.active"
        :slider-data="blocksData.heroSlider.data"
      />

      <div
        v-if="blocksData.video && blocksData.video.active"
        class="hp-video-container"
      >
        <HomepageVideo
          video-ref="hp-video"
          :data="blocksData.video.data"
          :hasMuteIcon="true"
          :has-play-icon="false"
        />
      </div>

      <LazyHydrate
        when-visible
        v-if="blocksData.classics && blocksData.classics.active"
      >
        <FeaturedCollection :featured-collection-data="blocksData.classics" />
      </LazyHydrate>

      <LazyHydrate
        when-visible
        v-if="blocksData.collectionHome && blocksData.collectionHome.active"
      >
        <CollectionHome :data="blocksData.collectionHome.data" />
      </LazyHydrate>

      <LazyHydrate
        when-visible
        v-if="
          blocksData.projectThroughLightning &&
          blocksData.projectThroughLightning.active
        "
      >
        <HeroImage :data="blocksData.projectThroughLightning.data" />
      </LazyHydrate>

      <LazyHydrate
        when-visible
        v-if="
          blocksData.blogCarousel &&
          blocksData.blogCarousel.active &&
          blocksData.blogCarousel.data
        "
      >
        <LatestStories
          :title="blocksData.blogCarousel.data.title"
          :link="blocksData.blogCarousel.data.link"
          :storiesData="blocksData.blogCarousel.data.posts"
        />
      </LazyHydrate>

      <LazyHydrate
        when-visible
        v-if="blocksData.featuredProduct && blocksData.featuredProduct.active"
      >
        <FeaturedProduct :data="blocksData.featuredProduct.data" />
      </LazyHydrate>

      <LazyHydrate
        when-visible
        v-if="blocksData.findYourStyle && blocksData.findYourStyle.active"
      >
        <FindYourStyle :data="blocksData.findYourStyle.data" />
      </LazyHydrate>

      <LazyHydrate when-visible>
        <NeedHelp />
      </LazyHydrate>

      <LazyHydrate
        when-visible
        v-if="blocksData.brandSlider && blocksData.brandSlider.active"
      >
        <BrandSlider :data="blocksData.brandSlider.data" />
      </LazyHydrate>

      <LazyHydrate when-visible>
        <HandCraftedBlock
          v-if="blocksData.handcrafted && blocksData.handcrafted.active"
          :handcrafted-data="blocksData.handcrafted.data"
          :videoInsteadOfImage="true"
        />
      </LazyHydrate>
    </div>
    <LoadingDots v-else />
    <Website />
    <Organization />
  </div>
</template>

<script type="module">
import {
  computed,
  defineComponent,
  ref,
  useContext,
  useFetch,
  useRoute,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@gemini/vsf-cache';
import HeroSlider from '~/components/Homepage/HeroSlider/HeroSlider.vue';
import { HeroImage } from '~/components/Home';
import HandCraftedBlock from '~/components/Home/HandCraftedBlock.vue';
import LazyHydrate from 'vue-lazy-hydration';
import LatestStories from '~/components/Homepage/LatestStories.vue';
import FeaturedProduct from '~/components/Homepage/FeaturedProduct.vue';
import HomepageVideo from '~/components/Homepage/HomepageVideo.vue';
import FindYourStyle from '~/components/Home/FindYourStyle.vue';
import BrandSlider from '~/components/BrandSlider.vue';
import CollectionHome from '~/components/CollectionHome.vue';
import NeedHelp from '~/components/General/NeedHelp.vue';
import FeaturedCollection from '~/components/Home/FeaturedCollection.vue';
import homeBlocks from '~/helpers/home/homeBlocks';
import { useGtm } from '~/composables';
import { LoadingDots } from '~/components/General';
import { useGetAlternateMatching } from '~/helpers/alternate/getAlternateMatching';
import { handleSeo } from '~/helpers/seo/handleSeo';
import cacheControl from '~/helpers/cacheControl';
import Organization from '~/components/StructuredData/Organization.vue';
import Website from '~/components/StructuredData/Website.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    LazyHydrate,
    LoadingDots,
    FindYourStyle,
    HeroImage,
    HeroSlider,
    FeaturedProduct,
    HomepageVideo,
    LatestStories,
    HandCraftedBlock,
    BrandSlider,
    CollectionHome,
    NeedHelp,
    FeaturedCollection,
    Organization,
    Website,
  },
  middleware: cacheControl({
    'max-age': 60,
    'stale-when-revalidate': 5,
  }),
  setup() {
    const { app } = useContext();
    const { getAlternatePageArray } = useGetAlternateMatching();
    const referenceDataSeoAlternate = ref([]);
    referenceDataSeoAlternate.value = getAlternatePageArray();

    const { getCanonical } = handleSeo();
    const canonicalLink = computed(() => getCanonical());

    const { addTags } = useCache();
    const { pageGtmPush } = useGtm();

    const { getHomeBlocks } = homeBlocks();
    const blocksData = ref();
    const route = useRoute();
    const languageCode = computed(() => {
      try {
        const localeCode = route.value.fullPath.split('/')[1];
        return localeCode.split('-')[1];
      } catch {
        return 'en';
      }
    });

    const metaDescription = computed(() => {
      switch (languageCode.value) {
        case 'en': {
          return 'Genuine Murano glass chandeliers ceiling fixtures for sale by Sogni Di Cristallo. Murano lighting worked by high qualified venetian glass masters at the venetian glass furnaces.';
        }
        case 'de': {
          return 'Kronleuchter aus Muranoglas Murano Beleuchtung zum Verkauf';
        }
        case 'fr': {
          return 'Tous les modles de Lustres en verre de Murano vendre, style classique et moderne, Fait Venise en Italie.';
        }
        case 'es': {
          return 'Colaboramos con firmas internacionales de arquitectura y diseo en el diseo y suministro de iluminacion para hoteles, restaurantes y areas comerciales';
        }
        default: {
          return "Lampadari, Plafoniere, Applique in Vetro di Murano Sogni di Cristallo; realizzati a mano nelle fornaci veneziane in Italia dagli esperti maestri vetrai. Le collezioni Sogni Di Cristallo; includono lampadari, applique, plafoniere, lampade in stile classico veneziano, moderno, luxury ca' rezzonico.";
        }
      }
    });

    useFetch(async () => {
      blocksData.value = await getHomeBlocks();
      addTags([{ prefix: CacheTagPrefix.View, value: 'homepage' }]);
      pageGtmPush('Home');
    });

    const metaTitle = computed(() =>
      app.i18n.t('Sogni Di Cristallo® - Artisans Of Light')
    );

    return {
      metaTitle,
      blocksData,
      metaDescription,
      referenceDataSeoAlternate,
      canonicalLink,
    };
  },
  head() {
    return {
      title: this?.metaTitle,
      meta: [
        { hid: 'robots', name: 'robots', content: 'index, follow' },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this?.metaDescription,
        },
        {
          hid: 'description',
          name: 'description',
          content: this?.metaDescription,
        },
        { hid: 'og:title', property: 'og:title', content: this?.metaTitle },
        //og:url
        {
          hid: 'og:url',
          property: 'og:url',
          content: this?.canonicalLink?.href,
        },
        // add fb:app_id
        { hid: 'fb:app_id', property: 'fb:app_id', content: '208478719244524' },
      ],
      // eslint-disable-next-line no-unsafe-optional-chaining
      link: [...this?.referenceDataSeoAlternate, this?.canonicalLink],
    };
  },
});
</script>
