import { useContext } from '@nuxtjs/composition-api';
import { useContent } from '@gemini-vsf/composables';
import { contentGetters } from '~/composables';

const manageClassicsData = (classicsData) => {
  const classicsCardsData = [];
  if (classicsData?.data?.length > 0) {
    classicsData.data.forEach((element) => {
      classicsCardsData.push({
        active: element.active,
        image: {
          desktop: {
            url: element.image.desktop,
          },
          mobile: {
            url: element.image.mobile,
          },
          alt: element.image.alt,
        },
        text: {
          top_text: element.subtitle,
          main_text: element.title,
          content: element.text,
        },
        cta: {
          label: element.link['link-label'],
          link: element.link['link-url'],
        },
        theme: element.theme,
        background: element.background,
      });
    });
  }
  return {
    active: classicsData?.active,
    data: {
      cards: [...classicsCardsData],
    },
  };
};

const homeBlocks = () => {
  const {
    app: {
      i18n: {
        localeProperties: { localeValue },
      },
    },
  } = useContext();
  const { blocks, loadBlocks } = useContent(`content-home-${localeValue}`);

  const getHomeBlocks = async () => {
    const featuredProductSelector = `featured-product-${localeValue}`;
    const handcraftedSelector = `hp-handcrafted-${localeValue}`;
    const projectThroughLightningSelector = `ptl-${localeValue}`;
    const heroSliderSelector = `hero-slider-${localeValue}`;
    const videoSelector = `hp-video-${localeValue}`;
    const brandSliderSelector = `hp-logo-slider-${localeValue}`;
    const findYourStyleSelector = `hp-products-overview-${localeValue}`;
    const classicsSelector = `classics-${localeValue}`;
    const collectionHomeSelector = `hp-categories-overview-${localeValue}`;
    const blogCarouselSelector = `hp-blog-carousel-${localeValue}`;

    await loadBlocks({
      identifiers: [
        featuredProductSelector,
        handcraftedSelector,
        projectThroughLightningSelector,
        heroSliderSelector,
        classicsSelector,
        collectionHomeSelector,
        videoSelector,
        brandSliderSelector,
        findYourStyleSelector,
        blogCarouselSelector,
      ],
    });

    const blockData = await contentGetters.getCmsBlockContent(blocks.value);

    return {
      featuredProduct: blockData[featuredProductSelector],
      handcrafted: blockData[handcraftedSelector],
      video: blockData[videoSelector],
      collectionHome: blockData[collectionHomeSelector],
      brandSlider: blockData[brandSliderSelector],
      findYourStyle: blockData[findYourStyleSelector],
      projectThroughLightning: blockData[projectThroughLightningSelector],
      heroSlider: blockData[heroSliderSelector],
      blogCarousel: blockData[blogCarouselSelector],
      classics: manageClassicsData(blockData[classicsSelector]),
    };
  };

  return {
    getHomeBlocks,
  };
};

export default homeBlocks;
